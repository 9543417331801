import React from 'react';
import { textColor } from '../../../theme';
import { mediaContainer } from '../../../media';
import { containerWidths } from '../../../styles';
import styled from 'styled-components';
import { Button } from '../../../Button';
import { Link } from 'react-scroll';
import { Link as LinkRouter } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as LogoTextSVG } from '../../../img/logo-text.svg';

const Content = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  min-height: 100vh;
  flex-flow: column wrap;
  width: 100%;
  ${mediaContainer};
`;

const Summary = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  max-width: 1100px;
  align-self: center;
  flex-grow: 1;
  padding-top: 50px;

  h1 {
    margin-bottom: 0;
  }
  
  .by {
    font-size: 1.9rem;
    margin-top: 0;
    margin-bottom: 0.7rem;

    @media(min-width: ${containerWidths.mobile + 1}px) {
      font-size: 2.3rem;
    }
    @media(min-width: ${containerWidths.tablet + 1}px) {
      font-size: 2.8rem;
    }
    > div {
      font-size: 0.6em;
      display: inline-block;
      font-weight: normal;
      margin-top: 0rem;
      margin-left: 1rem;
      margin-right: 1rem;
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      align-items: center;
      width: 100%;

      .inline-logo {
        height: 0.8em;
        display: inline-block;
        margin-left: 0.18em;
      }
    }
  }

  @media(min-width: ${containerWidths.mobile + 1}px) {
    padding-top: 95px;
  }

  padding-bottom: 0rem;
  word-break: break-word;

  button {
    margin: 0em 0.6em 0.75rem 0;
    font-size: 0.9rem;

    &:first-child {
      margin-left: 0;
    }

    @media(min-width: ${containerWidths.mobile + 1}px) {
      margin: 0em 0.5em 1em 0.4em;
      font-size: 1rem;
    }
    @media(min-width: ${containerWidths.tablet + 1}px) {
      margin: 0em 0.75em 1em 0.5em;
    }
  }
`;

const Collaborations = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 2.5rem;
  max-width: 1100px;
  @media(min-width: ${containerWidths.mobile + 1}px) {
    margin-top: 0;
  }
  
  h3 {
    margin-bottom: 1.5em;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    height: auto;
    margin: 1.5rem 0 1rem 0;

    @media(min-width: ${containerWidths.mobile + 1}px) {
      margin: 0rem 0 1rem 0;
    }

    img {
      height: 45px;
      margin: 0.5rem 1rem;

      @media(min-width: ${containerWidths.mobile + 1}px) {
        height: 50px;
        margin: 0.75rem 1.5rem;
      }
      @media(min-width: ${containerWidths.tablet + 1}px) {
        height: 65px;
        margin: 1rem 2rem;
      }

      path {
        fill: ${textColor};
      }
    }
  }
`;

export const Landing: React.FC = () => {

  return (
    <Content>
      <Summary>
        <h1>Construction Management Plans</h1>
        <div className='by'>
          <div>
            By <LogoTextSVG className='inline-logo' />
          </div>
        </div>
        <h2 className='subtitle'>
          A Construction Management Plan (CMP) or a Construction Site Traffic Management Plan (CTMP) is a document that councils require for most developments, to be submitted, as part of the Planning Approval process, in a growing number of areas and boroughs both in London and other major UK cities.
        </h2>
        <p>
          <Link
            to="about"
            smooth="easeOutBack"
            duration={750}
            href="#about"
            className="inline-block"
          >
            <Button label="More Information" />
          </Link>
          <LinkRouter
            to="/contact"
            className="inline-block"
          >
            <Button label="Contact Us" />
          </LinkRouter>
        </p>
      </Summary>

      <Collaborations>
  <Tooltip />
  <div>
    <h2>Previous Collaborations</h2>
    <div>
      {[...Array(18)].map((_, i) => {
        const id = `c${i + 1}`;
        const imageDetails = {
          c1: { name: 'Aviva', tooltip: 'Aviva' },
          c2: { name: 'Dorsett', tooltip: 'Dorsett Hotels & Resorts' },
          c3: { name: 'Imperial College London', tooltip: 'Imperial College London' },
          c4: { name: 'Columbia Threadneedle', tooltip: 'Columbia Threadneedle' },
          c5: { name: 'Equinox', tooltip: 'Equinox' },
          c6: { name: 'Body Worlds', tooltip: 'Body Worlds' },
          c7: { name: 'MATT Architecture', tooltip: 'MATT Architecture' },
          c8: { name: 'APT', tooltip: 'APT (Formerly Robin Partington & Partners)' },
          c9: { name: 'Ashton Bennett', tooltip: 'Ashton Bennett' },
          c10: { name: 'Harrison Design', tooltip: 'Harrison Design' },
          c11: { name: 'Arcadis', tooltip: 'Arcadis' },
          c12: { name: 'The Collective', tooltip: 'The Collective' },
          c13: { name: 'SAV Group', tooltip: 'SAV Group' },
          c14: { name: 'Clague Architects', tooltip: 'Clague Architects' },
          c15: { name: 'HCL Technologies', tooltip: 'HCL Technologies' },
          c16: { name: 'Empyrean Developments', tooltip: 'Empyrean Developments' },
          c17: { name: 'Morris+Company', tooltip: 'Morris+Company' },
          c18: { name: 'Allies and Morrison', tooltip: 'Allies and Morrison' }
        };

        return (
          <img
            key={id}
            src={require(`../../../img/${id}.webp`)}
            alt={imageDetails[id].name}
            data-tip={imageDetails[id].tooltip}
            loading="lazy"
            decoding="async"
          />
        );
      })}
    </div>
  </div>
</Collaborations>
    </Content>
  );
}

export default Landing;
