import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button } from '../../Button';
import { mediaContainer } from '../../media';

const SEO = () => (
  <Helmet>
    <title>404 - Page Not Found | CMP Construct</title>
    <meta name="robots" content="noindex" />
    <meta name="description" content="The page you are looking for cannot be found. Return to CMPConstruct homepage." />
  </Helmet>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
  padding: 2rem;
  ${mediaContainer};
  max-width: 800px;
  margin: 0 auto;

  .error-code {
    color: #14456D;
    font-size: 6rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  h1 {
    color: #14456D;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  p {
    color: #2C3E50;
    margin-bottom: 2rem;
    line-height: 1.6;
    font-size: 1.1rem;
  }

  .buttons {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
  }
`;

export const NotFound = () => {
  return (
    <>
      <SEO />
      <Wrapper>
        <div className="error-code">404</div>
        <h1>Page Not Found</h1>
        <p>The page you are looking for cannot be found or no longer exists.<br />Please use one of the links below to continue.</p>
        <div className="buttons">
          <Link to="/">
            <Button label="Home" />
          </Link>
          <Link to="/contact">
            <Button label="Contact" />
          </Link>
        </div>
      </Wrapper>
    </>
  );
};

export default NotFound;