import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Wrapper } from './Wrapper';

interface Borough {
  id: string;
  name: string;
  tooltip: string;
}

const BOROUGHS: Borough[] = [
  { id: 'b18', name: 'Barking & Dagenham', tooltip: 'Barking' },
  { id: 'b17', name: 'Brent', tooltip: 'Brent' },
  { id: 'b6', name: 'Camden', tooltip: 'Camden' },
  { id: 'b4', name: 'City of London', tooltip: 'City of London' },
  { id: 'b11', name: 'Ealing', tooltip: 'Ealing' },
  { id: 'b8', name: 'Enfield', tooltip: 'Enfield' },
  { id: 'b1', name: 'Hammersmith & Fulham', tooltip: 'Hammersmith & Fulham' },
  { id: 'b13', name: 'Haringey', tooltip: 'Haringey' },
  { id: 'b12', name: 'Harrow', tooltip: 'Harrow' },
  { id: 'b9', name: 'Havering', tooltip: 'Havering' },
  { id: 'b19', name: 'Islington', tooltip: 'Islington' },
  { id: 'b3', name: 'Lambeth', tooltip: 'Lambeth' },
  { id: 'b16', name: 'Merton', tooltip: 'Merton' },
  { id: 'b20', name: 'Richmond-upon-Thames', tooltip: 'Richmond-upon-Thames' },
  { id: 'b5', name: 'Royal Borough of Kensington and Chelsea', tooltip: 'Royal Borough of Kensington and Chelsea' },
  { id: 'b2', name: 'Southwark', tooltip: 'Southwark' },
  { id: 'b15', name: 'Tower Hamlets', tooltip: 'Tower Hamlets' },
  { id: 'b10', name: 'Waltham Forest', tooltip: 'Waltham Forest' },
  { id: 'b14', name: 'Wandsworth', tooltip: 'Wandsworth' },
  { id: 'b7', name: 'Westminster', tooltip: 'Westminster' }
];

export const Partners: React.FC = () => {
  return (
    <Wrapper>
      <Tooltip />
      <h2 className="text-2xl font-bold mb-8 text-center">CMPs produced for the following London Boroughs</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 px-4">
        {BOROUGHS.map(({ id, name, tooltip }) => (
          <div key={id} className="flex items-center justify-center p-4">
            <img
              src={require(`../../../img/${id}.webp`)}
              alt={name}
              data-tip={tooltip}
              loading="lazy"
              decoding="async"
              className="w-auto h-20 object-contain"
            />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Partners;