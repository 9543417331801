import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { About } from './pages/About';
import { WhatIs } from './pages/WhatIs';
import Home from './pages/Home';
import { Contact } from './pages/Contact/';
import { NotFound } from './pages/NotFound';

export const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <Routes>
      <Route path="/what-is-a-construction-management-plan" element={<WhatIs />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/" element={<Home />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
}